import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IOrderForDetail, IOrderForList } from "../../../interfaces/order";
import { IUserProfile } from "../../../interfaces/user";
import { RootStore } from "../../../stores/RootStoreContext";
import { ICatalogueForOrder } from "../../../interfaces/catalogue";
import { ClientOrderStatusType } from "../../../options/OrderStatusOption";

export class AllClientOrdersStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable loadingDetail: boolean = false;
    @observable orders: IOrderForList[] = []
    @observable searchText: string = "";
    @observable statuses: ClientOrderStatusType[] = [];
    @observable users: IUserProfile[] = [];
    @observable orderDetail?: IOrderForDetail = undefined;
    @observable products: ICatalogueForOrder[] = [];

    @action setOrderDetail = (value?: IOrderForDetail) => this.orderDetail = value;
    @action setProducts = (value: ICatalogueForOrder[]) => this.products = value;
    @action setLoading = (value: boolean) => this.loading = value;
    @action setLoadingDetail = (value: boolean) => this.loadingDetail = value;
    @action setSelectedStatuses = (value: ClientOrderStatusType[]) => this.statuses = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetOrders = (value: IOrderForList[]) => this.orders = value;
    @action setOrders = (value: IOrderForList[]) => {
        var existing = this.orders.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);
        this.orders.push(...missing);
    };
    @action setUsers = (value: IUserProfile[]) => {
        var existing = this.users.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.users.push(...missing);
    };

    @action first_load = () => {
        const { isAdmin } = this.rootStore.userStore;
        if (isAdmin) {
            this.statuses = ["client_unavailable", "pending_payment", "pending_processing", "payment_failed", "sent_to_market", "ready_for_pickup", "ready_for_delivery", "out_for_delivery"]
        }
    }

    @action load = async () => {
        try {
            this.setLoading(true);

            const { isAdmin } = this.rootStore.userStore;
            var response = await agent.Orders.get(this.searchText, this.statuses, this.currentPage);

            if (isAdmin)
                this.loadUsers(response.Items);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setOrders(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action refreshOrder = async (orderId: string) => {
        try {
            var response = await agent.Orders.refresh(orderId);
            runInAction(() => {
                var idx = this.orders.findIndex(f => f.id === orderId);
                this.orders[idx] = response;
            })
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action loadUsers = async (items: IOrderForList[]) => {
        try {
            var existingIds = this.users.map(f => f.id);
            var newOnes = items.map(f => f.userId);

            let missing = newOnes.filter(item => existingIds.indexOf(item) < 0);

            if (missing.length === 0) return;

            var profiles = await agent.Users.getMany(missing);
            if (profiles.length > 0)
                this.setUsers(profiles)

        } catch (error) {

        }
    }

    @action getDetail = async (id: string) => {
        try {
            this.setLoadingDetail(true);

            agent.Orders.getDetail(id)
                .then((orderDetail) => {
                    agent.Catalogue.Order.getByIds(orderDetail.items.map(f => f.catalogueId)).then((products) => {
                        this.setOrderDetail(orderDetail)
                        this.setProducts(products)
                    }).finally(() => this.setLoadingDetail(false));
                })

        } catch (error) {

        }
    }

    @action search = async (text: string) => {
        try {
            this.searchText = text;
            this.resetOrders([]);
            this.setCurrentPage(0)

            this.load();

        } catch (error) {
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetOrders([]);
        this.setHasMore(false);
    }

    @action disposeDetail = () => {
        this.setLoadingDetail(false);
        this.setOrderDetail(undefined);
        this.setProducts([]);
    }
}