import { action, makeObservable, observable } from "mobx";
import agent from "../api/agent";
import { RootStore } from "./RootStoreContext";

export class GlobalConfiguration {
    rootStore: RootStore;

    @observable loading: boolean = false;

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;

    }

    @observable deliveryEnabled: boolean = false;
    @observable sixPackCount: number = 1;

    @action setDelivery = (value: boolean) => this.deliveryEnabled = value;
    @action setSixPackCount = (value: number) => this.sixPackCount = value;


    @action initialize_global_config = () => {
        agent.GlobalConfig.isDeliveryEnabled().then((value) => this.setDelivery(value ?? false))
        agent.GlobalConfig.getSixPackCount().then((value) => this.setSixPackCount(value ?? false))
    }


}