import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Card } from 'semantic-ui-react';

const ReportsList = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;
    const { isMobile } = context.deviceStore;

    useEffect(() => {
        if (!isAdmin) {
            navigate("/")
            return;
        }
        return () => {
        }
    }, [isAdmin, navigate])

    const items = [];
    items.push({
        header: "VAT Summary",
        description: "A report with the sales per VAT.",
        onClick: () => navigate("/reports/vat-summary"),
    });
    items.push({
        header: "Itemized Sales",
        description: "A report with the sales per product.",
        onClick: () => navigate("/reports/itemized-sales"),
    });
    items.push({
        header: "Profit Report",
        description: "A report with the sales per day.",
        onClick: () => navigate("/reports/profit"),
    });
    items.push({
        header: "Wallet Balance Report",
        description: "A report with the users that have balance in their wallet.",
        onClick: () => navigate("/reports/wallet"),
    });
    items.push({
        header: "Wallet Transactions Report",
        description: "A report with all the transactions in the wallet of the user.",
        onClick: () => navigate("/reports/wallet-transactions"),
    });

    return (
        <div className="simply-paddedPage" >
            <Card.Group
                itemsPerRow={5}
                centered={isMobile}
                items={items}
            />
        </div>

    )
}

export default observer(ReportsList)