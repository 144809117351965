import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IAssociation, IAssociationForSave } from "../../../interfaces/association";
import { toast } from "react-toastify";
import { IProduct } from "../../../interfaces/product";

export class AssociationVerificationStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable counter: number = 0;
    @observable association?: IAssociation = undefined;
    @observable searchText?: string = undefined;

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setItem = (value?: IAssociation) => { this.association = value };
    @action setCount = (value: number) => { this.counter = value };

    @action load = async (searchText?: string) => {
        try {
            this.searchText = searchText;
            this.setLoading(true);

            agent.Associations.Unverified.count(this.searchText).then((value: number) => this.setCount(value));
            var item = await agent.Associations.Unverified.next(this.searchText);


            this.setItem(item);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action deleteAssociation = async (id: string) => {
        await agent.Associations.delete(id).then(() => {
            toast.success(`Done`)
            this.load(this.searchText)
        });
    }

    @action removeFromAssociation = async (product: IProduct) => {
        if (!this.association) return;

        var productId = product.id;
        var assId = this.association.id;

        if (!productId || !assId) return;

        await agent.Associations.removeFromAssociation(assId, productId);

        toast.success(`Done`)

        runInAction(() => {
            if (!this.association) return;
            this.association.products = this.association.products.filter(f => f.id !== productId);
            this.setItem(this.association);
        })
    }

    @action update = async (item: IAssociationForSave) => {
        if (!this.association) return;

        await agent.Associations.Unverified.verify(this.association.id, item).then(() => {
            toast.success(`Done`)
            this.load(this.searchText)
        });
    }

    @action dispose = () => {
        this.setItem(undefined)
        this.setLoading(false)
    }
}