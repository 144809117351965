import { observer } from 'mobx-react-lite'
import React, { Fragment, SyntheticEvent, useContext, useEffect } from 'react'
import { Button, Form, Icon, Image, Label, List, Popup, Table, TextArea } from 'semantic-ui-react'
import { RootStoreContext } from '../../../stores/RootStoreContext'
import { LoadingParagraph } from '../../../components/LoadingParagraph'
import CheckBox from '../../../form/CheckBox'
import ErrorMessage from '../../../form/ErrorMessage'
import TextInput from '../../../form/TextInput'
import { FORM_ERROR } from "final-form";
import { combineValidators, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import CreateUpdateCategoryWidget from '../../categories/CreateUpdateCategoryWidget'
import SelectInput from '../../../form/SelectInput'
import CreateUpdateMeasurementWidget from '../../measurementUnits/CreateUpdateMeasurementWidget'
import NumericInput from '../../../form/NumericInput'
import { compartments } from '../../../options/compartments'
import BrandsSelector from '../../../components/BrandsDropdown/BrandsSelector'
import DietaryCategoriesManageWidget from '../../dietaryCategories/DietaryCategoriesManageWidget'
import BrandDetailWidget from '../../brands/components/BrandDetailWidget'
import { toast } from 'react-toastify'
import arrayMutators from "final-form-arrays";
import { FieldArray } from 'react-final-form-arrays'
import { currencyFormat } from '../../products/functions/productHelper'
import CatalogueImageSelection from './CatalogueImageSelection'
import { BrandSelectorStore } from '../../../components/BrandsDropdown/functions/BrandSelectorStore'
import TextAreaInput from '../../../form/TextAreaInput'

interface IProps {
    editingId?: string
}

const validate = combineValidators({
    title: isRequired(""),
});


const AdminCatalogueDetailWidget: React.FC<IProps> = ({ editingId }) => {
    const context = useContext(RootStoreContext)
    const { openModal, closeModal } = context.modalStore;
    const {
        setEditingId,
        save,
        loadItemDetail,
        editingItem,
        categories,
        loadingDetail,
        measurements,
        refreshMeasurements,
        refreshCategories,
        dietaryCategories,
        refreshDietaryCategories,
        VATCategories,
        Extras,
        disposeDetail
    } = context.catalogueAdminStore;

    useEffect(() => {
        loadItemDetail();

        return () => { };
    }, [loadItemDetail, editingId]);

    return (
        <Fragment>
            <Icon style={{ position: "absolute", zIndex: '1' }} size='large' name='close' onClick={() => { setEditingId(undefined); disposeDetail(); }} />

            <div
                style={{ overflow: "auto", maxHeight: "80vh" }}
                className="fxFixed fxDisplay fxDirCol fxAlignCenter"
            >
                <FinalForm
                    onSubmit={(values: any) =>
                        save(values)
                            .then(() => toast.success("Saved", { autoClose: 1000 }))
                            .catch((error) => ({
                                [FORM_ERROR]: error,
                            }))
                    }
                    initialValues={editingItem ?? {}}
                    validate={validate}
                    mutators={{
                        ...arrayMutators,
                    }}
                    render={({
                        handleSubmit,
                        submitError,
                        dirtySinceLastSubmit,
                        submitting,
                        invalid,
                        pristine,
                        values,
                        form,
                        form: {
                            mutators: { push, remove },
                        },
                    }) => (
                        <Form onSubmit={handleSubmit} error>
                            <div
                                className="fxFixed fxDisplay fxDirCol fxAlignCenter"
                            >
                                <div
                                    style={{
                                        paddingLeft: "6px",
                                        paddingRight: "6px",
                                        maxWidth: "250px",
                                    }}
                                >
                                    <Image
                                        src={
                                            values.imageUrl ??
                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                        }
                                        onError={({ currentTarget }: any) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                        }}
                                    />
                                    {editingItem && editingItem.associationId && <Button
                                        className='button-as-link blueColor'
                                        content="Select/Upload Image"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            openModal(<CatalogueImageSelection onUpdate={(path: string) => { form.change("imageUrl", path) }} associationId={editingItem.associationId!} />, "large", "Select or upload an image", true, false)
                                        }} />}
                                </div>
                            </div>

                            <div style={{ minWidth: "400px", paddingTop: "15px" }}>
                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Brand"}</label>
                                        <BrandsSelector
                                            brandSelectStore={new BrandSelectorStore()}
                                            defaultValue={(editingItem as any)?.brand_tags}
                                            selectionChanged={(e, d: any) => {
                                                var arr: string[] = []
                                                if (Array.isArray(d.value)) {
                                                    arr = d.value
                                                }
                                                else {
                                                    arr = [d.value]
                                                }

                                                arr = arr.filter(f => f.trim() !== "");

                                                form.change("brand_tags", arr)
                                            }} />
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Create New Brand"
                                            onClick={() => {
                                                openModal(
                                                    <BrandDetailWidget
                                                        closeOnSave={true}
                                                        showCloseIcon={false}
                                                        close={() => { closeModal() }}
                                                    />,
                                                    "mini",
                                                    "Create new Brand",
                                                    true,
                                                    true,
                                                    () => {
                                                        // refreshDietaryCategories();
                                                    }
                                                );
                                            }}
                                        />

                                    </Form.Field>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"VAT Category"}
                                            {editingItem?.vatCat && editingItem?.vatCat.length > 0 &&
                                                <Popup trigger={<Icon style={{ paddingLeft: "5px" }} color='blue' name='info circle' />} content={<List>
                                                    {editingItem?.vatCat?.map((vtc, i) => (
                                                        <List.Item id={i}>{vtc}</List.Item>))}
                                                </List>} />}
                                        </label>
                                        {loadingDetail ? (
                                            <LoadingParagraph lines={2} />
                                        ) : (
                                            <Form.Field style={{ textAlign: "left" }}>
                                                <Field
                                                    loading={loadingDetail}
                                                    component={SelectInput}
                                                    options={VATCategories.map(v => ({
                                                        'key': v.id!,
                                                        'value': v.id!,
                                                        'text': v.title
                                                    }))}
                                                    name="vat"
                                                    placeholder={"Select VAT"}
                                                />
                                                {VATCategories.map((f) => (
                                                    <Label
                                                        color="blue"
                                                        basic
                                                        size="small"
                                                        style={{ marginTop: "4px" }}
                                                        content={f.title}
                                                        onClick={() => {
                                                            form.change(
                                                                "vat",
                                                                f.id!
                                                            );
                                                        }}
                                                    />
                                                ))}
                                            </Form.Field>

                                        )}
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Field width={16}>
                                        <label style={{ fontSize: "13px" }}>
                                            Extras
                                            {((editingItem?.bcrsAmount?.length ?? 0) > 0) &&
                                                <Popup trigger={<Icon style={{ paddingLeft: "5px" }} color='blue' name='info circle' />} content={<List>
                                                    {editingItem?.bcrsAmount?.map((vtc, i) => (<List.Item id={i}>{currencyFormat(vtc, true)}</List.Item>))}
                                                </List>} />}
                                        </label>
                                        <Table celled>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        Item
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Quantity
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Unit Cost
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Total Cost
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                <FieldArray name="extras">
                                                    {({ fields }) =>
                                                        fields.map((name, index) => (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    {loadingDetail ? (
                                                                        <LoadingParagraph lines={1} />
                                                                    ) : (
                                                                        <Field
                                                                            name={`${name}.id`}
                                                                            component={SelectInput}
                                                                            options={Extras.map(x => ({ 'key': x.id, 'value': x.id, 'text': x.title }))}
                                                                            changeFinished={(value: any) => {
                                                                                if (value === "") {
                                                                                    form.change(`${name}.fee`, 0);
                                                                                    form.change(`${name}.vat_rate_id`, undefined);
                                                                                }
                                                                                else {
                                                                                    form.change(`${name}.fee`, Extras.filter(f => f.id === value)[0].fee);
                                                                                    form.change(`${name}.vat_rate_id`, Extras.filter(f => f.id === value)[0].vat_rate);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        loading={loadingDetail}
                                                                        component={NumericInput}
                                                                        name={`${name}.quantity`}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    <Field
                                                                        loading={loadingDetail}
                                                                        component={NumericInput}
                                                                        name={`${name}.fee`}
                                                                    />
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {currencyFormat((values.extras[index]?.quantity ?? 0) * (values.extras[index]?.fee ?? 0), true)}
                                                                </Table.Cell>

                                                                <Table.Cell disabled={loadingDetail}>
                                                                    <Button
                                                                        color="red"
                                                                        icon="minus"
                                                                        circular
                                                                        size="mini"
                                                                        onClick={(event: SyntheticEvent) => {
                                                                            remove("extras", index);
                                                                            event.preventDefault();
                                                                        }}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                    }
                                                </FieldArray>
                                            </Table.Body>

                                            <Table.Footer fullWidth>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan="9">
                                                        <Button
                                                            disabled={loadingDetail}
                                                            floated="right"
                                                            basic
                                                            color="blue"
                                                            onClick={(event: SyntheticEvent) => {
                                                                push("extras", {});
                                                                event.preventDefault();
                                                            }}
                                                        >
                                                            <Icon name="add" />{" "}
                                                            Add
                                                        </Button>
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Footer>
                                        </Table>
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Product Title"}</label>
                                        {loadingDetail ? (
                                            <LoadingParagraph lines={2} />
                                        ) : (
                                            <Field
                                                loading={loadingDetail}
                                                component={TextInput}
                                                name="title"
                                                placeholder={"Product Title"}
                                            />
                                        )}
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Format Title"
                                            onClick={() => {
                                                const words = (values.title as string).split(' ').filter(f => f.trim() !== '');

                                                // Transform the words into camelCase
                                                let result = words
                                                    .map((word: string, index: number) => {
                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                    })
                                                    .join(' '); // Join the words back together

                                                form.change("title", result)
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>{"Searchable Title"}</label>
                                        {loadingDetail ? (
                                            <LoadingParagraph lines={2} />
                                        ) : (
                                            <Field
                                                loading={loadingDetail}
                                                component={TextAreaInput}
                                                name="norm_title"
                                                placeholder={"Product Title"}
                                            />
                                        )}
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Format Title"
                                            onClick={() => {
                                                const words = (values.title as string).split(' ').filter(f => f.trim() !== '');

                                                // Transform the words into camelCase
                                                let result = words
                                                    .map((word: string, index: number) => {
                                                        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                                                    })
                                                    .join(' '); // Join the words back together

                                                form.change("title", result)
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>{"Image URL"}</label>
                                    {loadingDetail ? (
                                        <LoadingParagraph lines={2} />
                                    ) : (
                                        <Field
                                            loading={loadingDetail}
                                            component={TextInput}
                                            name="imageUrl"
                                            placeholder={"Image URL"}
                                        />
                                    )}
                                </Form.Field>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Dietary Categories"}
                                        </label>
                                        <Field
                                            loading={loadingDetail}
                                            component={SelectInput}
                                            multiselect={true}
                                            options={dietaryCategories?.map((x) => ({
                                                key: x.id,
                                                value: x.id,
                                                text: x.description,
                                            }))}
                                            name="dietFlag"
                                            placeholder={"Select a category"}
                                        />
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Create New Dietary Category"
                                            onClick={() => {
                                                openModal(
                                                    <DietaryCategoriesManageWidget />,
                                                    "mini",
                                                    "Create new Dietary Category",
                                                    true,
                                                    true,
                                                    () => {
                                                        refreshDietaryCategories();
                                                    }
                                                );
                                            }}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <TextArea value={values.words?.join(',')} />

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Compartment"}
                                        </label>
                                        <Field
                                            loading={loadingDetail}
                                            component={SelectInput}
                                            options={compartments}
                                            name="compartment"
                                            placeholder={"Select compartment"}
                                        />
                                        {compartments.map((f) => (
                                            <Label
                                                key={f.key}
                                                color="blue"
                                                basic
                                                size="small"
                                                style={{ marginTop: "4px" }}
                                                content={f.text}
                                                onClick={() => {
                                                    form.change(
                                                        "compartment",
                                                        f.value
                                                    );
                                                }}
                                            />
                                        ))}
                                    </Form.Field>

                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Categories"}
                                        </label>
                                        <Field
                                            loading={loadingDetail}
                                            multiselect={true}
                                            component={SelectInput}
                                            options={categories
                                                .slice()
                                                .sort((a, b) => a.description > b.description ? 1 : -1).map((x) => ({
                                                    key: x.id,
                                                    value: x.id,
                                                    text: x.description,
                                                }))}
                                            name="ct_categories"
                                            placeholder={"Select category(ies)"}
                                        />
                                        <Label
                                            className="button-as-link clickable blueColor"
                                            content="Create New Category"
                                            onClick={() => {
                                                openModal(
                                                    <CreateUpdateCategoryWidget
                                                        categories={categories}
                                                        showCloseIcon={false}
                                                        close={closeModal}
                                                    />,
                                                    "mini",
                                                    "Create new Category",
                                                    true,
                                                    false,
                                                    () => {
                                                        refreshCategories();
                                                    }
                                                );
                                            }}
                                        />
                                    </Form.Field>

                                </Form.Group>

                                <Form.Group widths={'equal'}>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Measurment"}
                                        </label>
                                        {loadingDetail ? (
                                            <LoadingParagraph />
                                        ) : (
                                            <Field
                                                component={NumericInput}
                                                name="measurment"
                                                placeholder={"Measurment e.g. 700"}
                                            />
                                        )}

                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content="100"
                                            onClick={() =>
                                                form.change(
                                                    "measurment",
                                                    100
                                                )
                                            }
                                        />
                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content="500"
                                            onClick={() =>
                                                form.change(
                                                    "measurment",
                                                    500
                                                )
                                            }
                                        />
                                        <Label
                                            color="blue"
                                            basic
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            content="750"
                                            onClick={() =>
                                                form.change(
                                                    "measurment",
                                                    750
                                                )
                                            }
                                        />
                                        <Label
                                            color="blue"
                                            size="small"
                                            basic
                                            style={{ marginTop: "4px" }}
                                            content="1000"
                                            onClick={() =>
                                                form.change(
                                                    "measurment",
                                                    1000
                                                )
                                            }
                                        />

                                    </Form.Field>
                                    <Form.Field style={{ textAlign: "left" }}>
                                        <label style={{ fontSize: "13px" }}>
                                            {"Measurement Unit"}
                                        </label>
                                        <Field
                                            loading={loadingDetail}
                                            component={SelectInput}
                                            options={measurements?.map((x) => ({
                                                key: x.key,
                                                value: x.key,
                                                text: x.description,
                                            }))}
                                            name="measurementUnit"
                                            placeholder={"Measurement Unit"}
                                        />
                                        <div className='fxFixed fxDisplay fxJustifyBetween'>
                                            <div className='fxStretch'>
                                                <Label
                                                    color="blue"
                                                    size="small"
                                                    basic
                                                    style={{ marginTop: "4px" }}
                                                    content="gr"
                                                    onClick={() =>
                                                        form.change("measurementUnit", "grams")
                                                    }
                                                />
                                                <Label
                                                    color="blue"
                                                    size="small"
                                                    basic
                                                    style={{ marginTop: "4px" }}
                                                    content="ml"
                                                    onClick={() =>
                                                        form.change("measurementUnit", "ml")

                                                    }
                                                />
                                            </div>
                                            <div style={{ margin: "auto" }}>
                                                <Label
                                                    className="button-as-link clickable blueColor"
                                                    content="Create"
                                                    onClick={() => {
                                                        openModal(
                                                            <CreateUpdateMeasurementWidget />,
                                                            "mini",
                                                            "Create new Measurement Unit",
                                                            true,
                                                            true,
                                                            () => {
                                                                refreshMeasurements();
                                                            }
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field style={{ textAlign: "left" }}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            name="free_delivery"
                                            component={CheckBox}
                                            type="checkbox"
                                            label={
                                                <span style={{ color: "purple" }}>Free Delivery</span>
                                            }
                                        />
                                    )}
                                </Form.Field>

                                <Form.Field style={{ textAlign: "left" }}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            name="largeContainer"
                                            component={CheckBox}
                                            type="checkbox"
                                            label={
                                                <span>Six Pack</span>
                                            }
                                        />
                                    )}
                                </Form.Field>

                                <Form.Field style={{ textAlign: "left" }}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            name="active"
                                            component={CheckBox}
                                            type="checkbox"
                                            label={
                                                <span style={{ color: "green" }}>Active</span>
                                            }
                                        />
                                    )}
                                </Form.Field>

                                <Form.Field style={{ textAlign: "left" }}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            name="deleted"
                                            component={CheckBox}
                                            type="checkbox"
                                            label={
                                                <span style={{ color: "red" }}>Deleted</span>
                                            }
                                        />
                                    )}
                                </Form.Field>
                            </div>

                            {submitError && !dirtySinceLastSubmit && (
                                <ErrorMessage error={submitError} />
                            )}
                            <div style={{ textAlign: "center" }}>
                                <Button.Group style={{ paddingTop: "15px" }}>
                                    <Button
                                        color="blue"
                                        disabled={(invalid && !dirtySinceLastSubmit)}
                                        loading={submitting}
                                        content={"Save"}
                                    />
                                    <Button.Or />
                                    <Button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setEditingId(undefined);
                                            disposeDetail();
                                        }}
                                        content={"Cancel"}
                                    />
                                </Button.Group>
                            </div>
                        </Form>
                    )}
                />
            </div>
        </Fragment >
    )
}

export default observer(AdminCatalogueDetailWidget);