import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext } from 'react'
import { Menu, MenuItemProps, Segment } from 'semantic-ui-react';
import ProfilePage from './ProfilePage';
import ClientCredits from '../credits/ClientCredits';
import { RootStoreContext } from '../../stores/RootStoreContext';
import UserRoles from './Components/UserRoles';

const ManageUser = () => {
    const context = useContext(RootStoreContext);
    const { isOwner } = context.userStore;

    const [activeTab, setActiveTab] = React.useState<"profile" | "wallet" | "roles">("profile")

    return (
        <Fragment>
            <Menu attached="top" tabular>
                <Menu.Item
                    name="profile"
                    content={"Profile"}
                    active={activeTab === "profile"}
                    onClick={(e: any, data: MenuItemProps) => {
                        setActiveTab("profile");
                    }}
                />
                <Menu.Item
                    name="wallet"
                    content={"Wallet"}
                    active={activeTab === "wallet"}
                    onClick={(e: any, data: MenuItemProps) => {
                        setActiveTab("wallet");
                    }}
                />
                {isOwner && <Menu.Item
                    name="roles"
                    content={"User Roles"}
                    active={activeTab === "roles"}
                    onClick={(e: any, data: MenuItemProps) => {
                        setActiveTab("roles");
                    }}
                />}
            </Menu>

            <Segment padded={"very"} attached="bottom" style={{ minHeight: "100%" }}>
                {activeTab === "profile" && (
                    <ProfilePage />
                )}
                {activeTab === "wallet" && (
                    <ClientCredits />
                )}
                {activeTab === "roles" && (
                    <UserRoles />
                )}
            </Segment>
        </Fragment>
    )
}

export default observer(ManageUser);