import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { Accordion, Card, Divider, Grid, Header, Image, List } from "semantic-ui-react";
import SupermarketCard from "./components/SupermarketCard";
import { Link } from "react-router-dom";
import React from "react";

const About = () => {
  const context = useContext(RootStoreContext);
  const { setPage } = context.analyticsStore;
  const { oneTimeLoad } = context.productStore;
  const { markets } = context.cacheStore;
  const { isMobile, downloadAppWeb } = context.deviceStore;

  const [activeIndex, setActiveIndex] = React.useState(-2)

  useEffect(() => {
    oneTimeLoad();
    setPage("About", window.location.pathname);

    return () => { };
  }, [oneTimeLoad, setPage]);

  const items = [
    {
      title: "What Areas do you Deliver to?",
      content: "Our delivery service is available to all customers ordering via our website, serving all locations in Malta and Gozo (excluding Comino)."
    },
    {
      title: "When buying from CheapTrolley, am I always securing the cheapest price out there?",
      content: "Not yet, but that's our goal. For now, we've partnered with 4 fantastic providers that offer some of the best prices in Malta. While we work towards securing the lowest prices across the board, we remain committed to transparency. Our aim is to continually show you how prices compare among various supermarkets and ensuring that you are getting the best value for your money."
    },
    {
      title: "Who are your partners?",
      content: "Two of our main partners is PAVI PAMA Supermarket & Greens Supermarkets, renowned for consistently offering competitive prices in Malta. We also collaborate with two more providers in our effort to offer you the best deals, and we are constantly working to expand our network and enhance your shopping experience."
    },
    {
      title: "What's the minimum order amount?",
      content: "Our minimum order amount is just 65 euros."
    },
    {
      title: "What are the Delivery Charges?",
      content: <div>Our delivery fee is a maximum of €3.50 per delivery. The higher your order amount, the lower your delivery fee will be, until it gets waived completely! Alternatively, you have the option to pick up your products directly from our
        {" "}<span className="clickable ct-green-text-dark" style={{ textAlign: "left", textDecorationLine: "underline" }} onClick={() => { window.open("https://www.google.com/maps/place/Connect+Services+Ltd/@35.8897975,14.4630538,17z/data=!3m1!4b1!4m6!3m5!1s0x130e51473b5e2ae7:0xf64016b0e07db6d4!8m2!3d35.8897932!4d14.4656341!16s%2Fg%2F11pwzzf7b4?entry=ttu", "_blank") }}>
          hub
        </span>, in which case the delivery fee will be waived.</div>
    },
    {
      title: "What are the Service Charges?",
      content: "When checking out through CheapTrolley, we apply a minimal fee of €2. We believe that considering the overall savings on your shopping, this fee is reasonable and hope you appreciate the value it adds."
    },
    {
      title: "How can I track my order?",
      content: "You can track your order by logging in to our website using your username and password. From there, you can navigate to your active orders and view the latest status. Additionally, you'll receive notifications via email when the order is ready for delivery or pickup."
    },
    {
      title: "What Payment Methods are Accepted?",
      content: "We currently accept Payment via Credit & Debit Cards. This requires entering your card information within our payment page to be able to process the order. BOV Cash Link Card, HSBC Quick Cash Card, APS Premier Card, VISA Cards, MasterCard Cards."
    },
    {
      title: "What are the Typical Delivery Timeframes?",
      content: < div > Typically, we provide next - day delivery services, subject to the availability of the delivery service.Delivery times are as follows:
        <List bulleted style={{ paddingTop: "3px", paddingBottom: "3px", paddingLeft: "13px" }}>
          <List.Item>Malta: Deliveries are scheduled from 8am to 7pm.</List.Item>
          <List.Item>Gozo: Deliveries are scheduled from 9am to 6pm.</List.Item>
        </List>
        Customers can select their preferred timeslot, either from 8am to 1pm or from 3pm to 6/7pm.
      </div >
    },
    {
      title: "I received an expired item.Can I return it?",
      content: <div>We sincerely apologize if you received an expired item. You can return the item with your next online purchase via our delivery courier or return it in person to our
        {" "}<span className="clickable ct-green-text" style={{ textAlign: "left", textDecorationLine: "underline" }} onClick={() => { window.open("https://www.google.com/maps/place/Connect+Services+Ltd/@35.8897975,14.4630538,17z/data=!3m1!4b1!4m6!3m5!1s0x130e51473b5e2ae7:0xf64016b0e07db6d4!8m2!3d35.8897932!4d14.4656341!16s%2Fg%2F11pwzzf7b4?entry=ttu", "_blank") }}>
          hub
        </span>. Regardless of the method you choose, you'll receive credit on your account for use on your next online purchase.</div>
    },
    {
      title: "Items were missing from my online purchase. What should I do?",
      content: <div>
        If you purchased an item online but have not received it with your delivery, it would either be because it is out of stock or not in good condition to be delivered. We would refund you the credit on your CheapTrolley account for use on your next online purchase. On the other hand, if the item is on the receipt but you have not received it with your delivery, please contact <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
          info@cheaptrolley.com
        </a> or use our Facebook online chat.</div>
    },
    {
      title: "When am I entitled for a refund?",
      content: <div>You may request a refund in the following circumstances:
        <List bulleted style={{ paddingTop: "4px", paddingLeft: "13px" }}>
          <List.Item>You have received expired products</List.Item>
          <List.Item>Items are missing from your bill</List.Item>
        </List>
      </div>
    },
    {
      title: "Is there a cost to use the website or the mobile application?",
      content: "No, there is no cost involved in using either the website or the mobile application.You can compare prices across all supermarkets using the comparison tool or the barcode scanner on the mobile app."
    },
    {
      title: "Can I list my grocery/supermarket on CheapTrolley if I do not have an online website?",
      content: <div>
        Absolutely! We welcome more groceries/supermarkets on CheapTrolley to provide consumers with the best prices. You can also benefit from selling your products through our website. For more information, please contact us at <a target="_blank" rel="noreferrer" href="mailto:info@cheaptrolley.com">
          info@cheaptrolley.com
        </a>. We would love to collaborate with supermarkets offering competitive prices.</div>
    },
    {
      title: "How can I contact customer service, and what are the service hours?",
      content: "You can reach customer care via email or Facebook chat. We strive to respond immediately during our working hours, which are from 9am to 8pm."
    }
  ]

  const panels = items.map((x, i) => (
    {
      key: i,
      index: i,
      title: {
        className: `faq-title ${activeIndex === i ? 'ct-green-text' : ''}`,
        content: x.title,
        icon: 'question',
      },
      content: {
        className: `${activeIndex === i ? 'active' : 'closed'}`,
        content: (
          <span className="faq-content">
            {x.content}
          </span>
        ),
      },
    }
  ))

  return (
    <div className={isMobile ? "" : "simply-paddedPage-about"} >
      <Grid stackable verticalAlign="middle">
        <Grid.Row centered={isMobile}>
          <Grid.Column width={9}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Cheap Trolley – We are here to help to you Make the Right Choices
            </Header>
            <p style={{ fontSize: "1.13em" }}>
              Cheap Trolley was born in 2022 out of a desire to simplify the grocery shopping experience. Like many of you, we were tired of the uncertainty and inefficiency of traditional shopping methods.
            </p>
            <p style={{ fontSize: "1.13em" }}>
              Inspired by our own challenges, we set out to create a solution that would streamline the process and bring clarity to fluctuating prices. Our vision is to make it easier for everyone to find the best deals without the hassle of visiting multiple stores.
            </p>
            <p style={{ fontSize: "1.13em" }}>
              At Cheap Trolley, we're committed to bringing transparency and convenience to your shopping experience. We understand the importance of saving both time and money, which is why we diligently update our prices and display the time of the last update from each store's website.
            </p>
            <p style={{ fontSize: "1.13em" }}>
              Join us on our journey to revolutionize your grocery shopping. Say goodbye to the struggles of price comparisons and hello to the simplicity and savings with Cheap Trolley!
            </p>
            <p style={{ fontSize: "1.13em" }}>
              For more information, please refer to our <Link to={"/disclaimer"}>Disclaimer</Link>,{" "}
              <Link to={"/terms"}>Terms of Use</Link> and{" "}
              <Link to={"/privacy-policy"}>Privacy Policy</Link> for more
              details.
            </p>
          </Grid.Column>
          <Grid.Column width={7}>
            <Image
              bordered
              centered
              rounded
              size="large"
              src="/early.svg"
              alt="Cheap Trolley Logo"
              style={{ border: "none" }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered={isMobile}>
          <Grid.Column width={9} verticalAlign="middle">
            <div>
              <div style={{ marginBottom: "0.5em" }}>
                <b>E-mail : </b>
                <a
                  className="grey"
                  href={`mailto:info@cheaptrolley.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@cheaptrolley.com
                </a>
              </div>
              <div style={{ marginBottom: "0.5em" }}>
                <b>Instagram : </b>
                <a
                  className="grey"
                  href={`https://www.instagram.com/cheaptrolley/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @cheaptrolley
                </a>
              </div>
              <div style={{ marginBottom: "0.5em" }}>
                <b>Facebook : </b>
                <a
                  className="grey"
                  href={`https://www.facebook.com/cheaptrolley`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cheaptrolley
                </a>
              </div>
              <div>
                <b>LinkedIn : </b>
                <a
                  className="grey"
                  href={`https://www.linkedin.com/company/cheap-trolley/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cheaptrolley
                </a>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={7} verticalAlign="middle" textAlign="center">
            <div style={isMobile ? { textAlign: "center" } : { width: "100%" }}>
              <Header size="large">Our mobile app is now available</Header>
              <div>
                <img
                  style={{
                    maxWidth: "150px",
                    padding: "5px",
                  }}
                  alt="app store button"
                  src={"/stores/ios.png"}
                  onClick={() => downloadAppWeb("ios")}
                />

                <img
                  style={{
                    maxWidth: "150px",
                    padding: "5px",
                  }}
                  alt="app store button"
                  src={"/stores/android.png"}
                  onClick={() => downloadAppWeb("android")}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider
        style={{
          width: "250px",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />
      <Header as="h3" style={{ fontSize: "2em", padding: "5px" }}>
        Frequently Asked Questions (FAQ)
      </Header>
      <div className="faq" style={{ paddingTop: "20px" }}>
        <Accordion
          style={{ padding: "5px" }}
          onTitleClick={(e, data) => {
            if (data.index as number === activeIndex) {
              setActiveIndex(-1);
            }
            else
              setActiveIndex(data.index as number)
          }} panels={panels} />
      </div>
      <Grid columns={1} style={{ marginTop: "20px" }}>
        <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <div style={isMobile ? { textAlign: "center" } : { width: "100%" }}>
              <Header size="large">Our mobile app is now available</Header>
              <div>
                <img
                  style={{
                    maxWidth: "200px",
                    padding: "5px",
                  }}
                  alt="app store button"
                  src={"/stores/ios.png"}
                  onClick={() => downloadAppWeb("ios")}
                />

                <img
                  style={{
                    maxWidth: "200px",
                    padding: "5px",
                  }}
                  alt="app store button"
                  src={"/stores/android.png"}
                  onClick={() => downloadAppWeb("android")}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider
        style={{
          width: "250px",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />

      <Header
        as="h3"
        style={
          isMobile
            ? { fontSize: "2em", textAlign: "center" }
            : { fontSize: "2em" }
        }
      >
        Stores
      </Header>
      <Card.Group centered className="displayGrid">
        {markets
          .slice()
          .sort((a, b) => (a.title < b.title ? -1 : 1))
          .map((x) => (
            <SupermarketCard key={x.id} shop={x} />
          ))}
      </Card.Group>
    </div>
  );
};

export default observer(About);
